import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FetchEventsResponse } from '../../lib/apiResponse'
import DKEvent from '../../models/DKEvent'
import { DKAPI } from '../../lib/api'
import { RootState } from '../store'

export interface EventsState{
  items?: DKEvent[]
  isFetching: boolean
}

const initialState: EventsState = {
  isFetching: false
}

export const fetchEvents = createAsyncThunk('events/fetchEvents', async () => {
  let response = await DKAPI.fetch<FetchEventsResponse>('/events')  
  if(!response){
    return
  }
  return response.data.events
})

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state, action) => {
      state.isFetching = true
    })
    
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.isFetching = false
    })

    builder.addCase(fetchEvents.fulfilled, (state, action) => {  
      state.isFetching = false    
      if(action.payload){
        state.items = action.payload.sort((a, b) => {
          return a.date > b.date ? 1 : -1
        })
      }
    })
  }
})

export const actions = eventsSlice.actions
export const selectors = {
  allEvents: (state: RootState) => state.events.items,
  isFetching: (state: RootState) => state.events.isFetching,
  eventById: (state: RootState, id: String) => state.events.items?.find((event) => event._id === id)
}

export default eventsSlice