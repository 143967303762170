import React, { FunctionComponent } from 'react'

import DKEvent from '../models/DKEvent'

import EventItem from './eventItem'

interface EventListProps {
  events?: DKEvent[]
}

const EventList: FunctionComponent<EventListProps> = (props) => {
  return (
    <div className="eventList">
      {props.events?.map((event) => {
        return <EventItem {...event} key={event._id} />
      })}
    </div>
  )
}

export default EventList
