import React, { FunctionComponent } from 'react'

interface LogoProps {
  width?: number
  height?: number
}

const Logo: FunctionComponent<LogoProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? 226}
      height={props.height ?? 48}
      fill="none"
      viewBox="0 0 226 48"
    >
      <path
        fill="url(#paint0_linear)"
        d="M6.453 31.084V13.515h1.962c5.057 0 8.458 3.837 8.458 8.763s-3.445 8.806-8.458 8.806H6.453zM0 7.455v29.646h8.807c8.414 0 14.736-6.453 14.736-14.823 0-8.37-6.365-14.823-14.736-14.823H0z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        d="M27.326 11.291c0 2.093 1.7 3.837 3.837 3.837 2.093 0 3.837-1.744 3.837-3.837a3.842 3.842 0 00-3.837-3.836 3.815 3.815 0 00-3.837 3.836zm7.107 25.81V17.875h-6.54V37.1h6.54z"
      ></path>
      <path
        fill="url(#paint2_linear)"
        d="M49.704 22.54c2.267 0 3.706 1.177 3.793 3.051h6.497c-.306-4.447-4.273-8.152-10.508-8.152-6.409 0-10.681 4.577-10.681 10.07 0 5.494 4.01 10.028 10.681 10.028 5.843 0 9.723-3.314 10.377-7.586h-6.366c-.218 1.744-1.787 2.616-3.793 2.616-3.052 0-3.924-2.79-3.924-5.014 0-2.18.96-5.014 3.924-5.014z"
      ></path>
      <path
        fill="url(#paint3_linear)"
        d="M83.614 26.202c0-5.232-2.093-8.763-7.238-8.763-2.529 0-4.578 1.133-5.886 2.79V4.403h-6.54v32.698h6.54V27.51c0-2.616 1.614-4.796 3.663-4.796 2.049 0 2.92 1.22 2.92 3.488V37.1h6.54V26.2z"
      ></path>
      <path
        fill="url(#paint4_linear)"
        d="M96.417 22.932h3.488v-5.057h-3.488V10.55h-6.496v7.325h-3.488v5.057h3.488V37.1h6.496V22.93z"
      ></path>
      <path
        fill="url(#paint5_linear)"
        d="M109.917 28.6l6.757 8.5h8.371l-9.155-10.07 8.283-9.155h-8.109l-6.147 7.63V4.402h-6.54v32.698h6.54v-8.502z"
      ></path>
      <path fill="url(#paint6_linear)" d="M132.908 37.1V4.404h-6.54v32.698h6.54z"></path>
      <path
        fill="url(#paint7_linear)"
        d="M153.161 27.51c0 2.528-1.962 4.795-4.578 4.795-2.616 0-4.578-2.267-4.578-4.796 0-2.528 1.962-4.795 4.578-4.795 2.616 0 4.578 2.267 4.578 4.796zm6.54-9.592h-6.54v2.31c-1.7-2.092-4.142-2.79-6.583-2.79-5.45 0-9.548 4.753-9.548 10.072 0 5.318 4.098 10.027 9.548 10.027 2.441 0 5.275-.785 6.583-2.747v2.31h6.54V17.919z"
      ></path>
      <path
        fill="url(#paint8_linear)"
        d="M184.606 26.202c0-5.232-2.093-8.763-7.237-8.763-2.529 0-4.578 1.133-5.886 2.79v-2.354h-6.54V37.1h6.54V27.51c0-2.616 1.613-4.796 3.662-4.796s2.921 1.22 2.921 3.488V37.1h6.54V26.2z"
      ></path>
      <path
        fill="url(#paint9_linear)"
        d="M199.633 32.305c-2.616 0-4.578-2.267-4.578-4.84 0-2.528 1.962-4.795 4.578-4.795 2.616 0 4.578 2.267 4.578 4.796 0 2.572-1.962 4.84-4.578 4.84zm4.578-12.076c-1.875-2.18-4.142-2.79-6.584-2.79-5.449 0-9.548 4.708-9.548 10.027 0 5.319 4.099 10.07 9.548 10.07 2.442 0 5.276-.784 6.584-2.79v3.052c0 2.747-2.049 5.363-4.927 5.363-2.093 0-3.88-1.352-4.36-3.619h-6.54c.306 5.842 6.017 8.458 11.249 8.458 6.104 0 11.118-4.621 11.118-10.943V17.875h-6.54v2.354z"
      ></path>
      <path
        fill="url(#paint10_linear)"
        d="M218.763 4.796c.566 0 1.002.37 1.002.872 0 .48-.436.85-1.002.85h-.742V4.796h.742zm3.095 6.016l-1.875-3.226c.96-.61 1.352-1.243 1.352-1.918 0-1.221-1.177-2.224-2.572-2.224h-2.311v7.368h1.569V7.847h.524l1.373 2.965h1.94zm2.224-3.684c0 2.921-2.442 5.254-5.472 5.254-2.987 0-5.45-2.333-5.45-5.254 0-2.92 2.463-5.253 5.45-5.253 3.03 0 5.472 2.332 5.472 5.253zm1.918 0C226 3.204 222.665 0 218.61 0c-4.033 0-7.368 3.204-7.368 7.128s3.335 7.128 7.368 7.128c4.055 0 7.39-3.204 7.39-7.128z"
      ></path>
      <defs>
        <linearGradient id="paint0_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint6_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint7_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint8_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint9_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
        <linearGradient id="paint10_linear" x1="226.471" x2="0" y1="24.22" y2="24.22" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FB8567"></stop>
          <stop offset="1" stopColor="#784DF3"></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo
