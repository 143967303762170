export class FetchError extends Error {
  status: number
  err?: Error

  constructor(status: number, message?: string, prevError?: Error) {
    super(message)
    this.status = status
    this.err = prevError
  }
}

class API {
  baseURI: string
  apiPath: string
  onError?: Function

  constructor(baseURI: string, apiPath: string) {
    this.baseURI = baseURI
    this.apiPath = apiPath
  }

  setOnError(f: Function) {
    this.onError = f
  }

  async fetch<T>(endpoint: string) {
    try {
      let response = await fetch(this.baseURI + this.apiPath + endpoint)
      if (response.status >= 400) {
        throw new FetchError(response.status)
      }

      let json = await response.json()

      return json as T
    } catch (err) {
      if (this.onError) {
        this.onError(err)
      }
    }
  }

  getImageUrl(image?: string, size?: number) {
    if (!size) {
      size = 480
    }

    if (!image) {
      return
    }

    let uri = this.baseURI + '/images/' + image.replace('__(#size#)__', '_x' + size.toString())
    console.log(uri)
    return uri
  }
}

export default API

export interface APIResponse {
  status: number
}

export const DKAPI = new API(
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : `${window.location.protocol}//${window.location.hostname}:3001`,
  '/api/v1'
)
