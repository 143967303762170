import React, { FunctionComponent, useEffect, useState } from 'react'

interface LoadingSpinnerProps {
  isAnimating: boolean
  style?: any
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = (props) => {
  const [isAnimating, setIsAnimating] = useState<boolean>(props.isAnimating)

  useEffect(() => {
    setIsAnimating(props.isAnimating)
  }, [props.isAnimating])

  return <div className={`loadingSpinner ${isAnimating ? 'animating' : ''}`} style={{ ...props.style }} />
}

export default LoadingSpinner
