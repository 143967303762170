import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import DKEvent from '../models/DKEvent'
import { DKAPI } from '../lib/api'
import { currencyFormatter, dateFormatter, timeFormatter } from '../lib/formatter'

let eventImageStyle = (url?: string) => {
  if (!url) {
    return {}
  }
  return {
    backgroundImage: `url('${url}')`,
  }
}

const EventItem: FunctionComponent<DKEvent> = (event) => {
  let formattedPrice = event.price && event.price !== 0 ? currencyFormatter.format(event.price!) : 'Frei'
  let formattedDate = dateFormatter.format(new Date(event.date))
  let formattedTime = timeFormatter.format(new Date(event.date))
  const seatsAvailable = () => {
    if (event.maxParticipants.count >= 0) {
      return event.ticketsSold < event.maxParticipants.count ? 'Verfügbar' : 'Nicht verfügbar'
    }
    return 'Verfügbar'
  }

  return (
    <Link to={`/events/${event._id}`} style={{ color: 'inherit', textDecoration: 'inherit', width: '100%' }}>
      <div className="eventItem">
        <div
          className="image"
          style={eventImageStyle(
            event.imageURL ? DKAPI.getImageUrl(event.imageURL, 480) : '/images/dichtklang_default_cover.png'
          )}
        />
        <div className="info">
          <div className="top">
            <div className="title">{event.title}</div>
            <div className="location">
              {event.location.title} - {event.location.zip} {event.location.city}{' '}
            </div>
            <div className="date">{`${formattedDate} um ${formattedTime} Uhr`}</div>
          </div>
          <div className="seatPriceBox">
            <div className="item">
              <div className="headline">Plätze</div>
              <div className="value">{seatsAvailable()}</div>
            </div>
            <div className="item">
              <div className="headline">Eintritt</div>
              <div className="value">{formattedPrice}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default EventItem
