import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../components/loadingSpinner'
import PrivacyDefault from '../components/privacyDefault'
import PrivacyEvent from '../components/PrivacyEvent'
import MainLayout from '../layouts/mainlayout'

import { selectors as eventSelectors, fetchEvents } from '../redux/slices/events'
import { RootState } from '../redux/store'

interface PrivacyPageProps {}
interface Params {
  id: string
}

let loadingSpinnerStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginTop: '-25px',
  marginLeft: '-25px',
}

const PrivacyPage: FunctionComponent<PrivacyPageProps> = (props) => {
  const { id } = useParams<Params>()
  console.log('id:', id)

  const event = useSelector((state: RootState) => eventSelectors.eventById(state, id))
  const isFetching = useSelector(eventSelectors.isFetching)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) {
      return
    }

    if (!event) {
      dispatch(fetchEvents())
      return
    }
  }, [dispatch, event, id])

  return (
    <MainLayout>
      <div className="textwrapper">
        <LoadingSpinner isAnimating={isFetching} style={loadingSpinnerStyle} />
        {event && !event?.organizer?.isDefaultOrganizer && <PrivacyEvent event={event} />}
        {!isFetching && event?.organizer?.isDefaultOrganizer && <PrivacyDefault />}
        {!isFetching && !event && <div>Diese Seite existiert nicht.</div>}
      </div>
    </MainLayout>
  )
}

export default PrivacyPage
