import React, { FunctionComponent, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import LoadingSpinner from '../components/loadingSpinner'
import MainLayout from '../layouts/mainlayout'
import { DKAPI } from '../lib/api'
import { currencyFormatter, dateFormatter, timeFormatter } from '../lib/formatter'

import { selectors as eventSelectors, fetchEvents } from '../redux/slices/events'
import { RootState } from '../redux/store'

import NewMap from '../components/NewMap'
import { DKEventLocation } from '../models/DKEvent'

interface EventDetailPageProps {}
interface Params {
  id: string
}

let loadingSpinnerStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginTop: '-25px',
  marginLeft: '-25px',
}

let eventImageStyle = (url?: string) => {
  if (!url) {
    return {}
  }
  return {
    backgroundImage: `url('${url}')`,
  }
}

const EventDetailPage: FunctionComponent<EventDetailPageProps> = (props) => {
  const { id } = useParams<Params>()
  const dispatch = useDispatch()
  const event = useSelector((state: RootState) => eventSelectors.eventById(state, id))
  const isFetching = useSelector(eventSelectors.isFetching)
  const [isShowingSellpoint, setIsShowingSellpoint] = useState<boolean>(false)
  const [locationToShow, setLocationToShow] = useState<DKEventLocation | undefined>(event?.location)

  useEffect(() => {
    if (!event) {
      dispatch(fetchEvents())
      return
    }
    setLocationToShow(isShowingSellpoint ? event?.sellpoint : event?.location)
  }, [dispatch, event, isShowingSellpoint])

  useEffect(() => {
    setLocationToShow(isShowingSellpoint ? event?.sellpoint : event?.location)
  }, [isShowingSellpoint, event])

  let isFree = event?.price === 0
  console.log('price:', event?.price)
  console.log('isFree:', isFree)

  let formattedPrice = event?.price && !isFree ? currencyFormatter.format(event.price) : 'Frei'
  let formattedDate = event?.date ? dateFormatter.format(new Date(event.date)) : ''
  let formattedTime = event?.date ? timeFormatter.format(new Date(event.date)) : ''

  let locationHeadline = isFree ? 'Veranstaltungsort' : 'Veranstaltungsort & Vorverkaufsstelle'

  const seatsAvailable = () => {
    if (event && event.maxParticipants.count >= 0) {
      return event.ticketsSold < event.maxParticipants.count ? 'Verfügbar' : 'Nicht verfügbar'
    }
    return 'Verfügbar'
  }

  let badgeTicketText = ''
  if (isFree) {
    if (event?.noRegistrationNeeded) {
      badgeTicketText =
        'Für dieses Event ist eine Teilnahme ohne Ticket möglich. Sei einfach mit deinem aufgeladenen Smartphone, Kopfhören und der installierten Dichtklang-App rechtzeitig vor Ort.'
    } else {
      badgeTicketText = 'Um am Event teilnehmen zu können, musst du über die App ein kostenloses Ticket holen.'
    }
  } else {
    badgeTicketText = 'Um am Event teilnehmen zu können, musst du an der Vorverkaufsstelle ein Ticket kaufen.'
  }

  return (
    <MainLayout backLink={{ location: '/', title: 'Zurück' }}>
      <LoadingSpinner isAnimating={isFetching} style={loadingSpinnerStyle} />
      {event && (
        <>
          <div className="splitlayout">
            <div className="area">
              <div className="eventItem full" style={{ height: 'auto' }}>
                <div
                  className="image"
                  style={eventImageStyle(
                    event.imageURL ? DKAPI.getImageUrl(event.imageURL, 480) : '/images/dichtklang_default_cover.png'
                  )}
                />
                <div className="info">
                  <div className="top">
                    <div className="title">{event.title}</div>
                    <div className="location">
                      {event.location.title} - {event.location.zip} {event.location.city}
                    </div>
                    <div className="date">{`${formattedDate} um ${formattedTime} Uhr`}</div>
                  </div>
                  <div className="seatPriceBox doubleLined">
                    <div className="item">
                      <div className="headline">Plätze</div>
                      <div className="value">{seatsAvailable()}</div>
                    </div>
                    <div className="item">
                      <div className="headline">Eintritt</div>
                      <div className="value">{formattedPrice}</div>
                    </div>
                  </div>

                  <div className="description">
                    {event.description?.split('\n').map(function (item, key) {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </div>

                  <div className="locationBox">
                    <div
                      className={isShowingSellpoint ? 'item' : 'item active'}
                      onClick={(e) => {
                        setIsShowingSellpoint(false)
                      }}
                    >
                      <strong>{event.sellpointIsLocation ? locationHeadline : 'Veranstaltungsort'}:</strong>
                      <br />
                      {event.location.title}
                      <br />
                      {event.location.streetNumber}
                      <br />
                      {event.location.zip} {event.location.city}
                      <br />
                      {(event.location.phone || event.location.mail) && <br />}
                      {event.location && event.location.phone && `Telefon: ${event.location.phone}`}
                      {event.location.phone && <br />}
                      {event.location && event.location.mail && `E-Mail: ${event.location.mail}`}
                      {event.location.mail && <br />}
                    </div>
                    {!event.sellpointIsLocation && (
                      <div
                        className={isShowingSellpoint ? 'item active' : 'item'}
                        onClick={(e) => {
                          setIsShowingSellpoint(true)
                        }}
                      >
                        <strong>Vorverkaufsstelle:</strong>
                        <br />
                        {event.sellpoint.title}
                        <br />
                        {event.sellpoint.streetNumber}
                        <br />
                        {event.sellpoint.zip} {event.sellpoint.city}
                        <br />
                        {(event.sellpoint.phone || event.sellpoint.mail) && <br />}
                        {event.sellpoint && event.sellpoint.phone && `Telefon: ${event.sellpoint.phone}`}
                        {event.sellpoint.phone && <br />}
                        {event.sellpoint && event.sellpoint.mail && `E-Mail: ${event.sellpoint.mail}`}
                        {event.sellpoint.mail && <br />}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="area">
              <NewMap location={isShowingSellpoint ? event.sellpoint : event.location} className="map" />
            </div>
          </div>
          <div className="badgeTicketText">{badgeTicketText}</div>
        </>
      )}
    </MainLayout>
  )
}

export default EventDetailPage
