import React, { FC } from 'react'
import { Link } from 'react-router-dom'

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  return (
    <div className="footer">
      <div className="item">
        <a href="https://play.google.com/store/apps/details?id=com.dichtklang.client" rel="noreferrer" target="_blank">
          <img alt="Jetzt bei Google Play" src="/images/gpb.png" />
        </a>
      </div>
      <div className="item">
        <a href="https://apps.apple.com/de/app/dichtklang/id1120517751" rel="noreferrer" target="_blank">
          <img alt="Laden im App Store" src="/images/asb.png" />
        </a>
      </div>
      <div className="item">
        <div>Links</div>
        <ul>
          <li>
            <Link to="/imprint">Impressum</Link>
          </li>
          <li>
            <Link to="/privacy">Datenschutz</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
