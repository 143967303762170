import React, { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EventList from '../components/eventList'

import LoadingSpinner from '../components/loadingSpinner'
import MainLayout from '../layouts/mainlayout'

import { selectors as eventSelectors, fetchEvents } from '../redux/slices/events'

interface EventPageProps {}

let loadingSpinnerStyle = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginTop: '-25px',
  marginLeft: '-25px',
}

const EventPage: FunctionComponent<EventPageProps> = (props) => {
  const isFetching = useSelector(eventSelectors.isFetching)
  const events = useSelector(eventSelectors.allEvents)

  const dispatch = useDispatch()

  useEffect(() => {
    if (events) {
      return
    }
    dispatch(fetchEvents())
  }, [dispatch, events])

  return (
    <MainLayout navigationTitle="Events">
      <LoadingSpinner isAnimating={isFetching} style={loadingSpinnerStyle} />
      <EventList events={events} />
    </MainLayout>
  )
}

export default EventPage
