import React, { FunctionComponent } from 'react'
import Logo from './logo'

import { BackLink } from '../layouts/mainlayout'
import { Link } from 'react-router-dom'

interface HeaderProps {
  backLink?: BackLink
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  return (
    <div className="header">
      {props.backLink && (
        <div>
          <Link to={props.backLink!.location}>{props.backLink!.title}</Link>
        </div>
      )}
      <div className="logowrapper">
        <Link to="/">
          <Logo width={150} />
        </Link>
      </div>
    </div>
  )
}

export default Header
