import React, { FunctionComponent } from 'react'
import Footer from '../components/footer'

import Header from '../components/header'

export interface BackLink {
  location: string
  title: string
}

interface MainLayoutProps {
  navigationTitle?: string
  backLink?: BackLink
}

const MainLayout: FunctionComponent<MainLayoutProps> = (props) => {
  return (
    <>
      <div className="mainlayout">
        <Header backLink={props.backLink} />
        <div className="content">
          {props.navigationTitle && <h1>{props.navigationTitle}</h1>}
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default MainLayout
