import React, { FunctionComponent } from 'react'

interface PrivacyDefaultProps {}

const PrivacyDefault: FunctionComponent<PrivacyDefaultProps> = (props) => {
  return (
    <div>
      <h1>Informationen zum Datenschutz</h1>
      <p>
        Verantwortlich für die Verarbeitung Ihrer Daten ist der Anbieter der App Dichtklang®, Richard Unger,
        Dr.-Wester-Str. 1, 51491 Overath.
      </p>

      <p>
        Die Datenverarbeitung dient dem Zweck, die in der geltenden Coronaschutzverordnung NRW vorgeschriebene einfache
        Rückverfolgbarkeit von bei der Veranstaltung anwesenden Personen sicherzustellen.
      </p>
      <p>
        Die Daten werden nach der Erhebung verschlüsselt, bleiben für einen Zeitraum von vier (4) Wochen auf einem in
        Deutschland befindlichen Server gespeichert und werden anschließend gelöscht.
      </p>
      <p>
        Die Daten werden nur auf behördliches Verlangen und/oder bei Vorliegen der gesetzlichen Voraussetzungen an die
        zuständige(n) Behörde(n) übermittelt. Rechtsgrundlage ist Art. 6 Abs. 1 lit. c) DSGVO i.V.m. § 8 Abs. 1 i.V.m. §
        2a Abs. 1 und 3 CoronaSchVO NRW i.V.m. §§ 16, 25, 28 Infektionsschutzgesetz.
      </p>
      <p>
        Die Bereitstellung der Daten durch Sie ist erforderlich, um Ihnen den Zutritt zu der Veranstaltung zu
        ermöglichen. Bei Vorliegen der gesetzlichen Voraussetzungen steht Ihnen ein Recht auf Auskunft, Berichtigung,
        Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und/oder Beschwerde bei einer Aufsichtsbehörde
        zu.
      </p>

      <h2>Links, Urheberrecht, AGBs:</h2>
      <h3>Haftung für Links:</h3>
      <p>
        Unsere Internetseiten enthalten Links, die auf Internetseiten und Angebote fremder Anbieter/ Dritter verweisen.
        Da wir keinen Einfluss auf Inhalt und Gestaltung der gelinkten Seiten haben, distanzieren wir uns ausdrücklich
        von deren Inhalten. Diese Erklärung gilt für alle auf unseren Internetseiten angebrachten Links und für alle
        Inhalte der Seiten, zu denen Links oder andere Werbemittel führen. Für die Richtigkeit und Verfügbarkeit der
        Angebote fremder Anbieter/ Dritter, die wir über unsere Internetseiten bewerben, übernehmen wir keine Gewähr.
      </p>
      <p>
        Eine ständige inhaltliche Kontrolle der Links und deren Inhalte ist jedoch ohne konkrete Anhaltspunkte einer
        Rechtsverletzung nicht zumutbar. Für den Fall einer Rechtsverletzung bitten wir um entsprechende Mitteilung. Wir
        werden den Link entsprechend umgehend entfernen.
      </p>

      <h3>Urheberrecht/Marken:</h3>
      <p>
        Die von uns erstellten Werke und Inhalte unterliegen dem deutschen Urheberrecht. Dies gilt auch für Werke und
        Inhalte von Dritten. Entsprechend ist für die Nutzung dieser Inhalte (z.B. im Rahmen von Bearbeitung,
        Verbreitung, Veröffentlichung, Vervielfältigung) die vorherige schriftliche Zustimmung des jeweils Berechtigten
        einzuholen. Für den Fall einer Rechtsverletzung bitten wir um entsprechende Mitteilung. Wir werden den Inhalt
        entsprechend umgehend entfernen.
      </p>
      <p>Alle genannten Marken gehören ihren jeweiligen Inhabern und unterliegen den jeweiligen Bestimmungen.</p>

      {/* <h3>Auszug aus den Allgemeinen Geschäftsbedingungen:</h3>
      <p>Genieße den „intensiven und dichten Klang“ ...</p>
      <p>
        Dichtklang* ist ein „intensives und dichtes Klang-Erlebnis“, wo ich mich mit Hilfe eines Smartphones (oder
        Tablets) mit eigenem Kopfhörer, inkl. der „Dichtklang-App“, in ein bestehendes, kostenloses Netzwerk einloggen,
        einen Stream empfangen und zusammen mit anderen Teilnehmern zuhören kann.
      </p>
      <ol>
        <li>
          Es ist untersagt, während eines Events Inhalte, die über das „Dichtklang®-System“ ausgestrahlt, oder gestreamt
          werden, mitzuschneiden (recording), zu kopieren, zu digitalisieren, diese weiterzuleiten, zu verbreiten, zu
          veräußern, zu teilen, oder weiterzustreamen.
        </li>
        <li>Der Veranstalter ist für das ordnungsgemäße Abführen der GEMA-Gebühren selbst verantwortlich.</li>
        <li>
          Der Veranstalter ist für sämtliche Verpflichtungen die sein „Event“ („Streaming-Dauer“) betreffen, selbst
          verantwortlich.
        </li>
      </ol>

      <small>*Hinweis: Die Dichtklang-App funktioniert nur, wo auch ein Dichtklang-Stream vorhanden ist.</small> */}

      <h3>Auszug aus den Allgemeinen Nutzungs- und Geschäftsbedingungen:</h3>
      <p>Genieße den „intensiven und dichten Klang“ ...</p>
      <p>
        Dichtklang
        <sup>
          <span style={{ fontSize: '0.55em' }}>&reg;</span>
        </sup>
        * ist ein „intensives und dichtes Klang-Erlebnis“, wo ich mich mit Hilfe eines Smartphones (oder Tablets) mit
        eigenem Kopfhörer, inkl. der „Dichtklang
        <sup>
          <span style={{ fontSize: '0.55em' }}>&reg;</span>
        </sup>
        -App“, in ein bestehendes, kostenloses Netzwerk einloggen, einen Stream empfangen und zusammen mit anderen
        Teilnehmern zuhören kann.
      </p>
      <ol>
        <li>
          Es ist untersagt, während eines Events Inhalte, die über das „Dichtklang®-System“ ausgestrahlt, oder gestreamt
          werden, mitzuschneiden (recording), zu kopieren, zu digitalisieren, diese weiterzuleiten (Filesharing), zu
          verbreiten, zu veräußern, zu teilen, oder weiterzustreamen.
        </li>
        <li>
          Falls bei einem Dichtklang
          <sup>
            <span style={{ fontSize: '0.55em' }}>&reg;</span>
          </sup>
          -Event die Möglichkeit besteht, über den Dichtklang
          <sup>
            <span style={{ fontSize: '0.55em' }}>&reg;</span>
          </sup>
          -Stream ins Internet zu gelangen, dann ist es grundsätzlich verboten, auf illegale Web-Seiten zu gehen!
          <br />
          D.h. Webseiten, die u.a. Inhalte aufzeigen, welche: rassistisch, pornografisch, sexistisch, frauenfeindlich,
          randgruppenfeindlich, religionsfeindlich, rechts.- oder linksradikal, etc. ausgerichtet sind.
          <br />
          Ferner Webseiten: die pornografische, Musik-, oder Film-Download-Möglichkeiten beinhalten, etc.
          <br />
          (Um hier nur einige zu nennen.)
        </li>
        <li>Der Veranstalter ist für das ordnungsgemäße Abführen der GEMA-Gebühren selbst verantwortlich.</li>
        <li>
          Der Veranstalter ist für sämtliche Verpflichtungen die sein „Event“ („Streaming-Dauer“) betreffen, selbst
          verantwortlich.
        </li>
      </ol>
      <small>
        *Hinweis: Die Dichtklang
        <sup>
          <span style={{ fontSize: '0.55em' }}>&reg;</span>
        </sup>
        -App funktioniert nur bei einem Event, wo auch ein „Dichtklang
        <sup>
          <span style={{ fontSize: '0.55em' }}>&reg;</span>
        </sup>
        -Stream“ vorhanden ist.
      </small>
    </div>
  )
}

export default PrivacyDefault
