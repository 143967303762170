import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './styles/index.scss'

import ScrollToTop from './components/ScrollToTop'

import EventPage from './pages/eventPage'
import EventDetailPage from './pages/eventDetailPage'
import GeneralPrivacyPage from './pages/GeneralPrivacyPage'
import PrivacyPage from './pages/PrivacyPage'
import ImprintPage from './pages/ImprintPage'

export enum Theme {
  light = 'light',
  dark = 'dark',
}

function App() {
  let [themeState, setThemeState] = useState<Theme>(Theme.light)

  useEffect(() => {
    if (!window.matchMedia || !window.matchMedia('(prefers-color-scheme: dark)')) {
      return
    }
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    setThemeState(isDarkMode ? Theme.dark : Theme.light)
    let matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
    if (!matchMedia || !matchMedia.addEventListener) {
      return
    }
    matchMedia.addEventListener('change', (e) => {
      if (e.matches) {
        setThemeState(Theme.dark)
      } else {
        setThemeState(Theme.light)
      }
    })
  }, [])

  useEffect(() => {
    document.documentElement.classList.remove('theme-light')
    document.documentElement.classList.remove('theme-dark')
    document.documentElement.classList.add(`theme-${themeState}`)
  }, [themeState])

  const toggleTheme = () => {
    switch (themeState) {
      case Theme.light:
        setThemeState(Theme.dark)
        break
      case Theme.dark:
        setThemeState(Theme.light)
    }
  }

  return (
    <ThemeContext.Provider value={{ theme: themeState, toggleTheme: toggleTheme }}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/events/:id" component={EventDetailPage} />
          <Route exact path="/privacy" component={GeneralPrivacyPage} />
          <Route exact path="/imprint" component={ImprintPage} />
          <Route path="/privacy/:id?" component={PrivacyPage} />
          <Route path="/" component={EventPage} />
        </Switch>
      </Router>
    </ThemeContext.Provider>
  )
}

export type ThemeContextProps = { theme: Theme; toggleTheme?: () => void }
export const ThemeContext = React.createContext<ThemeContextProps>({ theme: Theme.light })

export default App
