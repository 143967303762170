import React, { CSSProperties } from 'react'
import { DKEventLocation } from '../models/DKEvent'

declare const mapkit: any

export interface MapSearchResult {
  displayLines?: string[]
  coordinate: MapCoordinate
  locality: string
  postCode: string
  subLocality: string
  subThoroughfare: string
  thoroughfare: string
}

export interface MapCoordinate {
  latitude: number
  longitude: number
}

interface NewMapProps {
  style?: CSSProperties
  location?: DKEventLocation
  className?: string
  onResultChange?: (result: MapSearchResult) => void
  onCoordinateChange?: (coordinate: MapCoordinate) => void
}

const fetchToken = async () => {
  let res = await fetch(process.env.REACT_APP_MAPKITTOKEN ?? 'http://localhost:3001/api/v1/geo/mapkit_token/debug')
  let json = await res.json()
  return json.data
}

mapkit.init({
  authorizationCallback: async function (done: any) {
    done(await fetchToken())
  },
  language: 'de',
})

export class NewMap extends React.Component<NewMapProps> {
  map?: any
  wrapper: React.Ref<HTMLDivElement>
  divRef: HTMLDivElement | null = null

  setDivRef = (element: HTMLDivElement) => {
    this.divRef = element
  }

  constructor(props: NewMapProps) {
    super(props)
    this.wrapper = React.createRef<HTMLDivElement>()
  }

  componentDidMount() {
    if (this.divRef) {
      this.map = new mapkit.Map(this.divRef)
      if (this.props.location) {
        let coordinate = new mapkit.Coordinate(this.props.location.latitude, this.props.location.longitude)
        this.setMarkerFromMapCoordinate(coordinate)
        this.zoomTo(coordinate)
      } else {
        let coordinate = new mapkit.Coordinate(51.4, 10)
        this.zoomTo(coordinate, false, 1000000)
      }
    }
    console.log('MAP DID MOUNT')
  }

  componentWillUnmount() {
    console.log('MAP DID UNMOUNT')
    this.map?.destroy()
  }

  shouldComponentUpdate(nextProps: NewMapProps) {
    if (!nextProps.location || nextProps.location === this.props.location) {
      return false
    }
    let newCoordinate = new mapkit.Coordinate(nextProps.location.latitude, nextProps.location.longitude)
    this.setMarkerFromMapCoordinate(newCoordinate, true)
    return false
  }

  setMarkerFromMapCoordinate(coordinate: MapCoordinate, animated: boolean = false) {
    let newCoordinate = new mapkit.Coordinate(coordinate.latitude, coordinate.longitude)
    this.setMarker(newCoordinate, animated)
  }

  setMarker(coordinate: any, animated: boolean = false) {
    this.map.removeAnnotations(this.map.annotations)
    let marker = new mapkit.MarkerAnnotation(coordinate, {
      title: this.props.location?.title,
      subtitle: `
        ${this.props.location?.streetNumber},
        ${this.props.location?.zip} ${this.props.location?.city}
      `,
      displayPriority: 1000,
    })
    marker.selected = true
    marker.color = '#fb8567'
    this.map.addAnnotation(marker)
    this.zoomTo(coordinate, animated)
  }

  zoomTo(coordinate: any, animated: boolean = false, distance: number = 1000) {
    this.map.cameraDistance = distance
    if (animated) {
      this.map.setCenterAnimated(coordinate)
    } else {
      this.map.center = coordinate
    }
  }

  render() {
    return <div ref={this.setDivRef} className={this.props.className} style={this.props.style} />
  }
}

export default NewMap
